import { useEffect, useState } from 'react';
import { ToDo } from '../../domain/entities/ToDo';
import { IToDoRepository } from '../../domain/ports/repositories/IToDo.repository';
import moment from 'moment';
import { Task } from '../../domain/entities/Task';
import { ToDoRepositoryFirebaseSingleton } from '../../datas/firebase/ToDoRepository.firebase';
import _ from 'lodash';
import { TaskStat } from '../../domain/entities/TaskStat';
import { User } from '../../domain/entities/User';

export const useToDo = (
  name?: string
): {
  todo: ToDo | undefined;
  setDate: (date: number) => Promise<void>;
  taskChecked: (task: Task, checked: boolean) => Promise<void>;
  todoValidate: (validate: boolean) => Promise<void>;
  taskValidate: (taskName: string, validate: boolean) => Promise<void>;
  toSign: (isOk: boolean) => Promise<void>;
  getStatForUser: (user: User) => Promise<TaskStat | undefined>;
} => {
  const [repository] = useState<IToDoRepository>(
    ToDoRepositoryFirebaseSingleton
  );

  const [todo, setTodo] = useState<ToDo | undefined>(undefined);
  const [date, setterDate] = useState<number>(
    moment(moment.now()).startOf('d').valueOf()
  );

  const setDate = async (date: number) => {
    setterDate(date);
  };

  const loadTodo = async () => {
    if (name) {
      const newTodo = await repository.retrieveToDo({ name }, date);
      if (!_.isEqual(todo, newTodo)) {
        setTodo(newTodo);
      }
    }
  };

  const taskChecked = async (task: Task, checked: boolean): Promise<void> => {
    if (name && date === moment(moment.now()).startOf('d').valueOf()) {
      if (await repository.taskCheckChange({ name }, date, task, checked)) {
        await loadTodo();
      }
    }
  };

  const todoValidate = async (validate: boolean): Promise<void> => {
    if (name) {
      if (await repository.validateTodo({ name }, date, validate)) {
        await loadTodo();
      }
    }
  };

  const taskValidate = async (
    taskName: string,
    validate: boolean
  ): Promise<void> => {
    if (name) {
      if (await repository.validateTask({ name }, date, taskName, validate)) {
        await loadTodo();
      }
    }
  };

  const toSign = async (isOK: boolean): Promise<void> => {
    if (name) {
      if (await repository.signTodo({ name }, date, isOK)) {
        await loadTodo();
      }
    }
  };

  const getStatForUser = async (user: User): Promise<TaskStat | undefined> => {
    const end = moment(date).endOf('W').valueOf();
    const start = moment(date).startOf('W').valueOf();
    return await repository.getStatForUser(user, start, end);
  };

  useEffect(() => {
    if (name && date) {
      loadTodo();
    }
  }, [name, date]);

  return {
    todo,
    setDate,
    taskChecked,
    todoValidate,
    taskValidate,
    toSign,
    getStatForUser,
  };
};

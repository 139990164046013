import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { router } from './Router';
import './App.css';
import moment from 'moment';

const App: React.FC = () => {
  moment.updateLocale('fr', {
    week: {
      // Set the First day of week to Monday
      dow: 0,
    },
  });
  return (
    <div id="App" className="bg-gray-100">
      <RouterProvider router={router} />
    </div>
  );
};

export default App;

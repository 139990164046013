import { FC, useState } from 'react';
import { KeyboardCode } from '../KeyboardCode';
import { Task } from '../../../domain/entities/Task';

export const ValidTasksIsOkModal: FC<{
  id?: string;
  onValid?: (isOk: boolean) => void;
  onClose?: () => void;
  tasks: Task[];
  taskValidate: (taskName: string, validate: boolean) => void;
}> = ({
  id,
  onValid,
  onClose,
  tasks,
  taskValidate,
}: {
  id?: string;
  onValid?: (isOk: boolean) => void;
  onClose?: () => void;
  tasks: Task[];
  taskValidate: (taskName: string, validate: boolean) => void;
}) => {
  const [codeValid, setCodeValid] = useState<boolean>(false);

  return (
    <div
      id={id}
      tabIndex={-1}
      aria-hidden="true"
      className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div className="relative p-4 w-full max-w-2xl max-h-full">
        <div className="relative rounded-lg shadow bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t border-gray-600">
            <h3 className="text-xl font-semibold text-white">
              Accord de validation du parent
            </h3>
          </div>
          <div className="p-4 md:p-5 space-y-4 text-white">
            Les tâches sont-elles bien faites ?
          </div>
          <div className="p-4 md:p-5 space-y-4">
            {!codeValid && (
              <KeyboardCode
                code="23161110"
                codeUnlocked={() => setCodeValid(true)}
              />
            )}
            {codeValid && (
              <div>
                {tasks.length > 0 ? (
                  <div>
                    <ul className="tasks-list w-48 text-sm font-medium">
                      {tasks?.map((task: Task) => {
                        return (
                          <li
                            key={task.name}
                            onPointerUp={() =>
                              taskValidate(task.name, !task.isValidate)
                            }
                            className="link-tasks flex items-center ps-4 border border-gray-200 bg-gray-700 rounded border-gray-700 cursor-pointer text-white"
                          >
                            {task.checked ? 'V' : 'X'}
                            <label
                              htmlFor="bordered-checkbox-1"
                              className="container-checkbox cursor-pointer w-full py-4 ms-2 text-sm font-medium  border-gray-200 dark:border-gray-600"
                            >
                              <span className="text">{task.name}</span>
                              <input
                                type="checkbox"
                                checked={task.isValidate}
                                name="bordered-checkbox"
                                className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : (
                  <div>Aucunes tâches trouvées</div>
                )}
              </div>
            )}
          </div>
          <div className="flex items-center p-4 md:p-5 border-t rounded-b border-gray-600">
            {codeValid && (
              <button
                data-modal-hide="default-modal"
                onPointerUp={() => codeValid && onValid?.(true)}
                type="button"
                className="cursor-pointer focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800"
              >
                Signer
              </button>
            )}
            <button
              onPointerUp={() => onClose?.()}
              data-modal-hide="oncancel-modal"
              type="button"
              className="cursor-pointer ms-3 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 focus:z-10 bg-gray-700 text-gray-300 border-gray-500 hover:text-white hover:bg-gray-600 focus:ring-gray-600"
            >
              Signer plus tard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

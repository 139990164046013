import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES_APP } from '../../Router';

const HomePage: FC = () => {
  const router = useNavigate();

  function goToTaskUser(name: string) {
    router(ROUTES_APP.TASK_USER.replace(':name', name));
  }

  return (
    <div className="h-screen flex items-center justify-center">
      <div className="grow h-screen flex items-center justify-center">
        <button
          className="px-6 bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
          onPointerUp={() => goToTaskUser('Isaac')}
        >
          Isaac
        </button>
      </div>
      <div className="grow h-screen flex items-center justify-center">
        <button
          className="px-6 bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
          onPointerUp={() => goToTaskUser('Lily')}
        >
          Lily
        </button>
      </div>
      <div className="grow h-screen flex items-center justify-center">
        <button
          className="px-6 bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
          onPointerUp={() => goToTaskUser('Luka')}
        >
          Luka
        </button>
      </div>
    </div>
  );
};

export default HomePage;

import {
  QueryDocumentSnapshot,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';
import { Task } from '../../domain/entities/Task';
import { ToDo } from '../../domain/entities/ToDo';
import { User } from '../../domain/entities/User';
import { IToDoRepository } from '../../domain/ports/repositories/IToDo.repository';
import { db } from './Firebase.config';
import { TaskStat } from '../../domain/entities/TaskStat';

export class ToDoRepositoryFirebase implements IToDoRepository {
  private TasksByUserCollectionRef = collection(db, 'task_by_user');
  private TodoCollectionRef = collection(db, 'todo');

  public async retrieveToDo(user: User, date: number): Promise<ToDo> {
    const q = query(
      this.TodoCollectionRef,
      where('user.name', '==', user.name),
      where('date', '==', date)
    );

    const datas = (await getDocs(q)).docs;

    if (datas.length === 0) {
      const newTodo = await this.populate(user.name, date);
      const result = await addDoc(this.TodoCollectionRef, newTodo);
      return { id: result.id, ...newTodo };
    } else {
      return {
        id: datas.at(0)?.id,
        ...(datas.at(0)?.data() as unknown as ToDo),
      };
    }
  }
  public async taskCheckChange(
    user: User,
    date: number,
    task: Task,
    checked: boolean
  ): Promise<boolean> {
    let dataChange: boolean = false;
    const todoSnapshot = await this.retrieveToDoReference(user.name, date);
    if (todoSnapshot) {
      const todo = todoSnapshot.data() as ToDo;

      todo.tasks = todo.tasks.map((taskLoop: Task) => {
        if (task.name === taskLoop.name && task.checked !== checked) {
          taskLoop.checked = checked;
          dataChange = true;
        }
        return taskLoop;
      });

      if (dataChange) {
        await setDoc(todoSnapshot.ref, todo);
      }
    }
    return dataChange;
  }

  public async validateTodo(
    user: User,
    date: number,
    validate: boolean
  ): Promise<boolean> {
    let dataChange: boolean = false;
    const todoSnapshot = await this.retrieveToDoReference(user.name, date);
    if (todoSnapshot) {
      const todo = todoSnapshot.data() as ToDo;
      todo.isValidate = validate;
      dataChange = true;
      if (dataChange) {
        await setDoc(todoSnapshot.ref, todo);
      }
    }
    return dataChange;
  }

  public async validateTask(
    user: User,
    date: number,
    taskName: string,
    validate: boolean
  ): Promise<boolean> {
    let dataChange: boolean = false;
    const todoSnapshot = await this.retrieveToDoReference(user.name, date);
    if (todoSnapshot) {
      const todo = todoSnapshot.data() as ToDo;
      todo.tasks = todo.tasks.map((current: Task) => {
        if (current.name === taskName) {
          current.isValidate = validate;
          dataChange = true;
        }
        return current;
      });
      if (dataChange) {
        await setDoc(todoSnapshot.ref, todo);
      }
    }
    return dataChange;
  }

  public async signTodo(
    user: User,
    date: number,
    isOK: boolean
  ): Promise<boolean> {
    let dataChange: boolean = false;
    const todoSnapshot = await this.retrieveToDoReference(user.name, date);
    if (todoSnapshot) {
      const todo = todoSnapshot.data() as ToDo;
      todo.isOk = isOK;
      todo.isSign = true;
      dataChange = true;
      if (dataChange) {
        await setDoc(todoSnapshot.ref, todo);
      }
    }
    return dataChange;
  }

  public async getStatForUser(
    user: User,
    start: number,
    end: number
  ): Promise<TaskStat | undefined> {
    try {
      const q = query(
        this.TodoCollectionRef,
        where('user.name', '==', user.name),
        where('date', '>=', start),
        where('date', '<=', end)
      );

      const todos: ToDo[] = (await getDocs(q)).docs.map(
        (value: QueryDocumentSnapshot) => {
          return value.data();
        }
      ) as unknown as ToDo[];
      let total = 0;
      let checked = 0;

      for (const todo of todos) {
        for (const task of todo.tasks) {
          total++;
          if (
            (!todo.isSign && task.checked) ||
            (todo.isSign && task.isValidate)
          ) {
            checked++;
          }
        }
      }

      return { total, checked };
    } catch (e) {
      return undefined;
    }
  }

  private async populate(name: string, date: number): Promise<ToDo> {
    const todo: ToDo = {
      date,
      isSign: false,
      user: { name },
      isValidate: false,
      tasks: await this.loadTasksForUser(name),
    };

    return todo;
  }

  private async loadTasksForUser(name: string): Promise<Task[]> {
    const q = query(this.TasksByUserCollectionRef, where('user', '==', name));
    const docs = await getDocs(q);
    return Promise.all(
      docs.docs.map(async (d) => ({
        checked: false,
        isValidate: false,
        name: ((await getDoc(d.data().task)).data() as unknown as Task).name,
      }))
    );
  }

  private async retrieveToDoReference(
    user: string,
    date: number
  ): Promise<QueryDocumentSnapshot | undefined> {
    const q = query(
      this.TodoCollectionRef,
      where('user.name', '==', user),
      where('date', '==', date)
    );

    return (await getDocs(q)).docs.at(0);
  }
}

export const ToDoRepositoryFirebaseSingleton = new ToDoRepositoryFirebase();

import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBVkYaHGyzwwmzSYlhSz9g6hQ3u8zIbWkY',
  authDomain: 'taskchildren-a51d9.firebaseapp.com',
  projectId: 'taskchildren-a51d9',
  storageBucket: 'taskchildren-a51d9.appspot.com',
  messagingSenderId: '244705620035',
  appId: '1:244705620035:web:0f8de3fe7abf03656be4f6',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

import { FC, useEffect, useState } from 'react';

export const KeyboardCode: FC<{
  code: string;
  codeUnlocked: () => void;
}> = ({ code, codeUnlocked }: { code: string; codeUnlocked: () => void }) => {
  const [currentCode, setCurrentCode] = useState<string>('');

  useEffect(() => {
    if (code === currentCode) {
      codeUnlocked();
    }
  }, [currentCode]);

  function btnPressed(code: number) {
    setCurrentCode(currentCode + code.toString());
  }

  function deleteLastChar() {
    if (currentCode.length > 0) {
      setCurrentCode(currentCode.slice(0, -1));
    }
  }

  return (
    <div className="flex-column text-center">
      <div className="flex-row text-white h-10">
        {currentCode.split('').map(() => '*')}
      </div>
      <div className="flex flex-row justify-center">
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(7)}
          >
            7
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(8)}
          >
            8
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(9)}
          >
            9
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(4)}
          >
            4
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(5)}
          >
            5
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(6)}
          >
            6
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(1)}
          >
            1
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(2)}
          >
            2
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(3)}
          >
            3
          </button>
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <div>
          <button
            className="bg-transparent text-lg text-transparent font-semibold py-2 px-4 outline-none cursor-pointer"
            disabled={true}
          >
            *
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => btnPressed(0)}
          >
            0
          </button>
        </div>
        <div>
          <button
            className="bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow cursor-pointer"
            onPointerUp={() => deleteLastChar()}
          >
            &#171;
          </button>
        </div>
      </div>
    </div>
  );
};

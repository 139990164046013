import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES_APP } from '../../Router';
import { useToDo } from '../../hooks/useToDo';
import { Task } from '../../../domain/entities/Task';
import { ValidTasksIsOkModal } from '../../components/modal/ValidTasksIsOkModal';
import './TaskUserPage.scss';
import { TaskStat } from '../../../domain/entities/TaskStat';
import moment from 'moment';

export const TaskUserPage: FC = () => {
  const { name } = useParams<{ name: string }>();
  const navigate = useNavigate();
  const [showValidTasksIsOkModal, setShowValidTasksIsOkModal] =
    useState<boolean>(false);
  const [taskStat, setTaskStat] = useState<TaskStat>();

  const {
    todo,
    setDate,
    taskChecked,
    todoValidate,
    toSign,
    taskValidate,
    getStatForUser,
  } = useToDo(name);

  useEffect(() => {
    if (showValidTasksIsOkModal && todo?.isSign) {
      setShowValidTasksIsOkModal(false);
    }
  }, [todo]);

  async function retrieveStatTask() {
    if (name) {
      setTaskStat(await getStatForUser({ name }));
    }
  }

  function validateTodo() {
    todoValidate(!todo?.isValidate);
    setShowValidTasksIsOkModal(true);
  }

  function goToHome() {
    navigate(ROUTES_APP.HOME);
  }

  function disabledButtonValid(): boolean {
    return todo?.isSign || false;
  }

  function isToday(): boolean {
    return (
      moment(todo?.date).startOf('d').valueOf() ===
      moment(moment.now()).startOf('d').valueOf()
    );
  }

  function getDateName(): string {
    return isToday()
      ? "Aujourd'hui"
      : moment(todo?.date).format('dddd DD MMM YYYY');
  }

  function getNumberWeek(): string {
    return `(Semaine ${moment(todo?.date).utc().week()})`;
  }

  function previousDate(): void {
    setDate(moment(todo?.date).subtract(1, 'day').valueOf());
  }

  function nextDate(): void {
    setDate(moment(todo?.date).add(1, 'day').valueOf());
  }

  return (
    <div className="flex task-user-page bg-gray-100 flex-col">
      <div className="flex flex-col items-center">
        <div className="flex justify-center items-center">
          <div>
            <button
              className="mr-3 z-40 bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow min-w-[25px]"
              onPointerUp={() => {
                previousDate();
              }}
            >
              {'<'}
            </button>
            <span>
              {getDateName()} {getNumberWeek()}
            </span>
            <button
              disabled={isToday()}
              className={
                isToday()
                  ? 'ml-3 bg-white text-lg text-gray-200 font-semibold py-2 px-4 border border-gray-200 rounded shadow min-w-[25px]'
                  : 'ml-3 bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow min-w-[25px]'
              }
              onPointerUp={() => {
                !isToday() && nextDate();
              }}
            >
              {'>'}
            </button>
          </div>
        </div>
      </div>
      <div className="flex task-user-page bg-gray-100">
        {showValidTasksIsOkModal && (
          <ValidTasksIsOkModal
            onValid={(valid: boolean) => {
              toSign(valid);
            }}
            onClose={() => {
              setShowValidTasksIsOkModal(false);
            }}
            taskValidate={taskValidate}
            tasks={todo?.tasks || []}
          />
        )}
        <div>
          <div>
            <div>
              <button
                className="z-40 bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mb-3 min-w-[225px]"
                onPointerUp={() => goToHome()}
              >
                Accueil
              </button>
            </div>
            <div>
              <button
                className="z-40 bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mb-3 min-w-[225px]"
                onPointerUp={() => retrieveStatTask()}
              >
                Stats tâches
              </button>
            </div>
            {taskStat && (
              <div>
                <ul>
                  <li>
                    <strong>Stat de la semaine : </strong>
                  </li>
                  <li>Total tâches: {taskStat.total}</li>
                  <li>Total faites: {taskStat.checked}</li>
                  <li>Total non faites: {taskStat.total - taskStat.checked}</li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <div
          className={
            showValidTasksIsOkModal
              ? 'w-full flex align-center justify-center opacity-10'
              : 'w-full flex align-center justify-center'
          }
        >
          {todo?.tasks && todo.tasks.length > 0 ? (
            <div>
              <ul className="tasks-list w-48 text-sm font-medium">
                {todo?.tasks?.map((task: Task) => {
                  return (
                    <li
                      key={task.name}
                      onPointerUp={() =>
                        !todo?.isSign && taskChecked(task, !task.checked)
                      }
                      className="link-tasks flex items-center ps-4 border border-gray-200 bg-gray-700 rounded border-gray-700 cursor-pointer text-white"
                    >
                      <label
                        htmlFor="bordered-checkbox-1"
                        className="container-checkbox cursor-pointer w-full py-4 ms-2 text-sm font-medium  border-gray-200 dark:border-gray-600"
                      >
                        <span className="text">{task.name}</span>
                        <input
                          type="checkbox"
                          checked={task.checked}
                          name="bordered-checkbox"
                          className="cursor-pointer w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <span className="checkmark"></span>
                      </label>
                      {todo.isSign && (
                        <span>
                          {task.isValidate ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="green"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="red"
                              className="size-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                              />
                            </svg>
                          )}
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
              <div className="btn-valid flex align-items justify-center">
                <button
                  className={
                    disabledButtonValid()
                      ? 'bg-white text-lg text-gray-200 font-semibold py-2 px-4 border border-gray-200 rounded shadow'
                      : 'bg-white hover:bg-gray-100 text-lg text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
                  }
                  disabled={disabledButtonValid()}
                  onPointerUp={() => {
                    !disabledButtonValid() && validateTodo();
                  }}
                >
                  Contrôle des parents
                </button>
              </div>
            </div>
          ) : (
            <div>Aucunes tâches trouvées</div>
          )}
        </div>
      </div>
    </div>
  );
};

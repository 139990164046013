import { createBrowserRouter } from 'react-router-dom';
import HomePage from './pages/Home/HomePage';
import { TaskUserPage } from './pages/TasksUser/TaskUserPage';

export enum ROUTES_APP {
  HOME = '/',
  TASK_USER = '/task-user/:name',
}

export const router = createBrowserRouter([
  {
    path: ROUTES_APP.HOME,
    Component: HomePage,
  },
  {
    path: ROUTES_APP.TASK_USER,
    Component: TaskUserPage,
  },
]);
